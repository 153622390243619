.c-list-unordered {
    $el: '.c-list-unordered';
    margin-left: 0;
    padding: 0;
    list-style: none;
    
    &__link {
        color: $brand-blue-dark;
    }
    
    &__item {
        padding-left: 21px;
        margin-bottom: 10.5px;
        line-height: 24.5px;
        &:before {
            content: "›";
            color: $brand-green;
            font-size: 20px;
            position: relative;
            top: -2px;
            left: 2px;
            float: left;
            margin-left: -21px;
        }
    }
    &--tiny {
        #{$el}__item {
            line-height: 21px;
            margin-bottom: 3.5px;
        }
    }
    
    &--small {
        #{$el}__item {
            line-height: 21px;
            margin-bottom: 7px;
        }
    }
}
