/* ==========================================================================
   #SPACING
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 */

/* stylelint-disable string-quotes */

$spacing-directions: (
  null: null,
  '-top': '-top',
  '-right': '-right',
  '-bottom': '-bottom',
  '-left': '-left',
  '-horizontal': '-left' '-right',
  '-vertical': '-top' '-bottom',
) !default;

$spacing-properties: (
  'padding': 'padding',
  'margin': 'margin',
) !default;

$spacing-sizes: (
  null: $spacing-unit,
  '-tiny': $spacing-unit-tiny,
  '-small': $spacing-unit-small,
  '-large': $spacing-unit-large,
  '-x-large': $spacing-unit-x-large,
  '-huge': $spacing-unit-huge,
  '-none': 0
) !default;


@each $property-namespace, $property in $spacing-properties {

  @each $direction-namespace, $direction-rules in $spacing-directions {

    @each $size-namespace, $size in $spacing-sizes {

      .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {

        @each $direction in $direction-rules {
          #{$property}#{$direction}: $size !important;
        }

      }

    }

  }

}

.u-margin-horizontal-minus {
  margin-left: -$spacing-unit-small;
  margin-right: -$spacing-unit-small;
  
  @media(min-width: $screen-sm) {
    margin-left: -$spacing-unit;
    margin-right: -$spacing-unit;
  }
}

/* stylelint-enable string-quotes */
