.u-append-percent {
    &:after {
        content: "%";
        font-size: 16px;
    }
}


// utilities to work with existing ui js
.ui-tabs-hide {
	display: none !important;
}

.nav-pills > li {
	width: 25%;
}
.nav-pills > li + li{
	margin: 0 0 5px 0;
}
.nav-pills > li > a {
	padding: 10px 0;
}
.nav-pills > li.ui-state-active > a,
.nav-pills > li.ui-state-active > a:hover,
.nav-pills > li.ui-state-active > a:focus {
  color: #fff;
  background-color: #5fd5ca;
}
