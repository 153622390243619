#introduction {
    margin-bottom: $padding-large-vertical;
}
fieldset {
    // border: 1px solid $gray-warm-darker;
    // display: table-cell; 
    // width: 100%;
    background: $gray-warm;
    border-radius: $border-radius-base;
    margin-bottom: 10px;
	  padding: $padding-base-vertical $padding-base-horizontal;
    @media(min-width: $screen-sm) {
        padding: 0 $padding-large-horizontal  $padding-large-vertical;
    }
}

label {
    font-weight: 400 !important;
}

.form-horizontal {    
    .control-label {
        align-self: center;
        padding-top: 0px;
        text-align: left;
    }
    .radio-inline {
      padding-top: 0;
    }
}

.input-group-addon {
    padding: 0 $padding-base-horizontal;
}

.form-control {
    box-shadow:none;
    color: $text-color;
    padding: 0 $padding-small-horizontal;
    
    &:focus {
        box-shadow: 0 0 1px rgba(68, 131, 252, 0.5);
    }
    
    &-static { // extra information next to an input field
        min-height: 0;
        padding-bottom: 0;
        padding-left: 4px;
        padding-top: 0;
        flex: 1 0 auto;
    }
    &.has-euro {
        padding-left: 28px;
    }
}

.form-sbs { // side by side, for when there is extra information next to an input field
  align-items: center;
  display: flex;
}

label {
	display: inline;
    font-weight: 300;
}

select,
select.form-control {
    height: 36px;
    appearance: none;
    background-color: white;
    background-image: url(../../Images/caret-blue-down.svg);
    background-position:
    calc(100% - 10px) calc(1em + 0px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
    font-size: 15px;
    line-height: 30px;
    margin: 0;
    padding: 0 24px 0 7px; // reserve space for the dropdown arrow
    width: 100%;
    &::-ms-expand {
        display: none; // hide dropdown arrow for IE
    }
}

.form-group {
    border-radius: $border-radius-base;
    transition: .15s background ease-out;
    background: rgba(#ffffff, 0);
    padding: $padding-xs-vertical 0 ;
    margin-bottom: 0;
    @media (min-width: $screen-sm) {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }
    
    // fix Safari flex-children from overflowing when clearfix is applied by Bootstrap (causing the items to be placed under neath each other)
    .form-horizontal &:before,
    .form-horizontal &:after {
      display: none;
    }
    
    &:hover {
        background: rgba(#ffffff, 1);
    }
    
    & + & {
        margin-top: 3.5px;
    }
}


.EuroSign {
    color: $gray-light;
    position: absolute;
    left: 27px;
    top: 7px;
}

// Remove 'x' to clear input field in Internet Explorer/Edge (?)
input::-ms-clear {
    display: none;
}


/*
  Not sure if the code below is used?
  _____________________________________
*/

// METHOD 1
.field-validation-valid {
	position:absolute;
	margin-left:2px;
}

.field-validation-error {
	position:absolute;
	margin-left:2px;
	color: $brand-danger;
}
input[type=text].input-validation-error {
border:1px solid $brand-danger;
}
.field-validation-error + .field-validation-asterisk {
	display:none;
}


// METHOD 2
.field-validation-asterisk {
	position:absolute;
	margin-left:2px;
}
.error {
	.field-validation-asterisk {
		color: $brand-danger;	
	}
	input[type=text] {
		border:1px solid $brand-danger;
	}
}
