/* ==========================================================================
   #BOX
   ========================================================================== */

/**
 * The box object simply boxes off content. Extend with cosmetic styles in the
 * Components layer.
 *
 * 1. So we can apply the `.o-box` class to naturally-inline elements.
 */
 
.o-box {
  @include clearfix();
  display: block; /* [1] */
  padding: $spacing-unit;

  > :last-child {
    margin-bottom: 0;
  }

}



/* Size variants
   ========================================================================== */

.o-box--flush {
  padding: 0;
}

.o-box--tiny {
  padding: $spacing-unit-tiny;
}

.o-box--small {
  padding: $spacing-unit-small;
}

.o-box--large {
  padding: $spacing-unit-large;
}

.o-box--huge {
  padding: $spacing-unit-huge;
}




.o-box {
	border-radius: 4px;
	&--white {
		border: 1px solid $gray-warm-darker;
	}
	&--light-gray {
		background: rgba(243,242,241, .1);
		border: 1px solid $gray-warm-darker;
	}
	&--gray {
        background: $nearwhite;
        border: 1px solid $gray-warm-darker;
	}
	&--green {
		background-color: #5fd5ca;
		color: white;
	}

    &__divider {
        margin-left: -$spacing-unit;
        margin-right: -$spacing-unit;
    }
}
