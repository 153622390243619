/* ==========================================================================
   #RESPONSIVE-SPACINGS
   ========================================================================== */

/**
 * Utility classes enhancing the normal spacing classes by adding responsiveness
 * to them. By default this brings us the following classes:
 *
 *   .u-margin-bottom-none@mobile {}
 *   .u-margin-bottom-none@tablet {}
 *   .u-margin-bottom-none@desktop {}
 *   .u-margin-bottom-none@wide {}
 *
 * But we can affect the classes that get generated by altering the appropriate
 * Sass maps below.
 */



// When using Sass-MQ, this defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
//
//   <div class="u-margin-bottom@mobile">
//
// Be aware that since the `@` symbol is a reserved symbol in CSS, it has to be
// escaped with a `\`. In the markup though, you write your classes without the
// backslash (e.g. `u-margin-bottom@mobile`).

$widths-breakpoint-separator: \@ !default;

$responsive-spacing-directions: (
  null: null,
  '-top': '-top',
  '-right': '-right',
  '-bottom': '-bottom',
  '-left': '-left',
  '-horizontal': '-left' '-right',
  '-vertical': '-top' '-bottom',
) !default;

$responsive-spacing-properties: (
  'padding': 'padding',
  'margin': 'margin',
) !default;

$responsive-spacing-sizes: (
  null: $spacing-unit,
  '-tiny': $spacing-unit-tiny,
  '-small': $spacing-unit-small,
  '-large': $spacing-unit-large,
  '-x-large': $spacing-unit-x-large,
  '-huge': $spacing-unit-huge,
  '-none': 0
) !default;

$breakpoints: (
  'screen-xs': $screen-xs,
  'screen-sm': $screen-sm,
  'screen-md': $screen-md,
  'screen-lg': $screen-lg
) !default;

/* stylelint-disable max-nesting-depth */

@each $property-namespace, $property in $responsive-spacing-properties {

  @each $direction-namespace, $direction-rules in $responsive-spacing-directions {

    @each $size-namespace, $size in $responsive-spacing-sizes {

      @each $bp-name, $bp-value in $breakpoints {
        @media(min-width: $bp-value) {

          .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace}#{$widths-breakpoint-separator}#{$bp-name} {

            @each $direction in $direction-rules {
              #{$property}#{$direction}: $size !important;
            }

          }

        }

      }

    }

  }

}

/* stylelint-enable max-nesting-depth */
