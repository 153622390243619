// @import "custom-bs-variables";

// override Bootstrap variables
$gray-darker:             #263237;
$gray-dark:               #666666;
$gray:                    #999999;
$gray-light:              #cccccc;
$gray-lighter:            #e5e5e5;
$nearwhite:               #fafafa;
$white:                   #fff;
$gray-warm:               #f3f2f1;
$gray-warm-darker:        #e4e1dd;

$brand-blue-light:        #08b7eb; // logo, bg
$brand-blue:              saturate(lighten(#26447d, 15%), 10%) ; // #2c60c4 — links
$brand-blue-dark:         #26447d; // main header background color
$brand-blue-grey:         #b0bec5; // icons
$brand-green:             #5fd5ca; // signal green

$brand-primary:           $brand-blue;
$link-color:              $brand-primary;
$brand-success:           #37c089;
$brand-info:              $brand-blue;
$brand-warning:           #F2C11C;
$brand-danger:            #f4665c;

$nav-pills-active-link-hover-bg:   			$brand-green;
$nav-link-hover-bg: 										$gray-warm;

// Type
$text-color:              $gray-darker;
$font-family-sans-serif:  "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base:          15px;
$font-size-large:         ceil(($font-size-base * 1.133333333)); // ~17px
$font-size-small:         ceil(($font-size-base * 0.933333333)); // ~14px

$font-size-h1:            floor(($font-size-base * 1.866666667)); // ~28px
$font-size-h2:            floor(($font-size-base * 1.2)); // ~18px
$font-size-h3:            ceil(($font-size-base * 1.066666667)); // ~26px
$font-size-h4:            $font-size-base;
$font-size-h5:            ceil(($font-size-base * 0.933333333)); // ~14px
$font-size-h6:            ceil(($font-size-base * 0.866666667)); // ~13px

$headings-font-weight:    700;

$padding-base-vertical:     14px;
$padding-base-horizontal:   14px;
$padding-large-vertical:    28px;
$padding-large-horizontal:  28px;
$padding-small-vertical:    7px;
$padding-small-horizontal:  7px;
$padding-xs-vertical:       3.5px;
$padding-xs-horizontal:     3.5px;

$border-radius-base:        4px;
$border-radius-large:       4px;
$border-radius-small:       4px;

$input-border:              $gray-light;
$input-border-focus:        $brand-blue;
$input-color-placeholder:   #9e9d9c;
$input-height-base:         36px;

$btn-primary-bg:            $brand-green;
$btn-primary-border:        transparent;


// Alerts
$state-info-border:         #2c60c4;
$state-info-bg:             #f4f8ff;
$state-info-text:           $text-color;

$state-success-border:       #37c089;
$state-success-bg:           #eaf9f3;
$state-success-text:         $text-color;

$state-warning-border:      #f2c11c;
$state-warning-bg:          #fef9e6;
$state-warning-text:        $text-color;

$state-danger-border:       #f4665c;
$state-danger-bg:           #fff4f3;
$state-danger-text:         $text-color;

// Breakpoints
$screen-xs:                 360px;
$screen-sm:                 560px;
$screen-md:                 600px;
$screen-lg:                 1024px;

$grid-gutter-width:         28px;

/*!
 * Bootstrap v3.3.6 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "./bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

// Custom variables
$spacing-unit-tiny:    7px;
$spacing-unit-small:   14px;
$spacing-unit:         28px;
$spacing-unit-large:   42px;
$spacing-unit-x-large: 56px;
$spacing-unit-huge:    112px;

// Custom styles
@import "buttons";
@import "mixins";
@import "fonts";
@import "forms";
@import "tables";
@import "generic.layout";
@import "objects.box";
@import "components.list-unordered";
@import "components.calculation";
// Spacing utility classes
@import "utilities.spacing";
@import "utilities.responsive-spacing";
@import "utilities.misc";
@import "utilities.text";
