.c-calculation {
    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 6.5px;
        padding-top: 6.5px;

        & + & {
            border-top: 1px solid $gray-warm-darker;
        }
    }
    &__result {
        display: block;
        flex: 1 0 auto;
        padding-left: $spacing-unit-tiny;
        text-align: right;
    }
    &__label {
        display: block;
        font-weight: 700;
        flex: 0 1 auto;
    }
}
