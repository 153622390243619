body, button, input, optgroup, select, textarea {
  font-family: "Lato";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
    margin: 0px auto;
    max-width: 640px;
}
