//== Tables
.table-responsive {
	.table {
        margin-bottom: 0;
		max-width:none !important;
		> thead,
		> tbody {
			> tr {
                transition: .15s background ease-out;
                background: rgba(#ffffff, 0);
                
                &:hover {
                    background: rgba(#ffffff, 1);
                }
				> th {
                    white-space:normal;
				}
                > td {
                    white-space:normal;
                    border-top-color: $gray-warm-darker;
                }
            }
        }
        > thead {
            th {
                border-top-color: transparent !important;
            }
        }
    }
}


.c-table-responsive {
    position:relative;
    width:100%;
    .c-scroller {
      display: none;
      position: absolute;
      z-index: 101;
      top: 5px;
      background-color: $brand-green;
      color: #fff;
      border-radius: 0 13px 13px 0;
      cursor: pointer;
      padding: 7px 8px 7px 2px;
      font-size: 14px;
      text-decoration: none;
      line-height: 1;
      &:before {
        background: url("/Content/Images/arrow-scroller.svg");
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: inline-block;
        width: 14px;
        height: 12px;
      }
      &:hover,
      &:focus,
      &:active {
        background-color: darken($brand-green, 20);
      }
      &--left {
        left: 0;
      }
      &--right {
        right: 0;
        transform: rotate(180deg);
      }
    }
  .c-table-responsive__shadow {
    display: none;
    position: absolute;
    z-index: 100;
    top: 5px;
    bottom: 0;
    width: 10px;
    background-image: linear-gradient(-270deg, #E3E1DE 0%, rgba(237,235,234,0) 98%);
    &-left {
      left: 0;
    }
    &-right {
      right: 0;
      transform: rotate(180deg);
    }
  }
    .c-table-responsive__scroller {
        position:relative;
        border:none;
        overflow-x: auto;
        min-height: .01%;
    }
}
