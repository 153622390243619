@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url('../../fonts/Lato-regular/Lato-regular.eot');
  src: url('../../fonts/Lato-regular/Lato-regular.eot?#iefix') format('embedded-opentype'),
       local('Lato Regular'),
       local('Lato-regular'),
       url('../../fonts/Lato-regular/Lato-regular.woff2') format('woff2'),
       url('../../fonts/Lato-regular/Lato-regular.woff') format('woff'),
       url('../../fonts/Lato-regular/Lato-regular.ttf') format('truetype'),
       url('../../fonts/Lato-regular/Lato-regular.svg#Lato') format('svg');
}

@font-face {
  font-family: 'Lato';
  font-weight: 700;
  font-style: normal;
  src: url('../../fonts/Lato-700/Lato-700.eot');
  src: url('../../fonts/Lato-700/Lato-700.eot?#iefix') format('embedded-opentype'),
       local('Lato Bold'),
       local('Lato-700'),
       url('../../fonts/Lato-700/Lato-700.woff2') format('woff2'),
       url('../../fonts/Lato-700/Lato-700.woff') format('woff'),
       url('../../fonts/Lato-700/Lato-700.ttf') format('truetype'),
       url('../../fonts/Lato-700/Lato-700.svg#Lato') format('svg');
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  font-style: normal;
  src: url('../../fonts/Lato-900/Lato-900.eot');
  src: url('../../fonts/Lato-900/Lato-900.eot?#iefix') format('embedded-opentype'),
       local('Lato Black'),
       local('Lato-900'),
       url('../../fonts/Lato-900/Lato-900.woff2') format('woff2'),
       url('../../fonts/Lato-900/Lato-900.woff') format('woff'),
       url('../../fonts/Lato-900/Lato-900.ttf') format('truetype'),
       url('../../fonts/Lato-900/Lato-900.svg#Lato') format('svg');
}
