//clearfix
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin vertical-align {
	position:absolute;
	top:50%;
	transform: translateY(-50%);
}
